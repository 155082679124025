import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import axios from 'axios'
import { URLS, AFFILIATES, DEALERS} from './factories'
import {CloudinaryService, PistonHeadsCarAdvertService} from "./services";
import {RegExHelper, CookieHelper, RequestHelper, LocationHelper, CanCoutinuButtonHelper, AffiliatesHelper} from "./helpers";
import VehicleType from '@/const/vehicle_type';
import {DEFAULT_VALUE} from '@/const';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        progress: 0,
        pageName: '',
        fromGetMyQuote: false, 
        version: process.env.VUE_APP_VERSION,
        GUID: null,
        loanAmountUnknown: false,
        isMonthlyBudgetAllowed: false,
        showLoanAmount: true,
        dobDay: null,
        dobMonth: null,
        dobYear: null,
        fullLicence: CanCoutinuButtonHelper.canCoutinue() ? null : 35,
        subdomain: null,
        host: null,
        applicationId: null,
        vehicleId: null,
        vrm: null,
        token: {
            authenticationToken: null,
            authenticationTokenLifetime: 0,
            tokenUpdateTime: 0
        },
        cloudinaryVehicleImageUrl: null,
        affiliateConfig: {
            companyName: null,
            prePopulateForm: true,
            googleAnalytics: null,
            loadVehicleFromQuote: null,
            affiliateUserId: null
        },
        dealerConfig: {
            companyName: null,
            prePopulateForm: true,
            googleAnalytics: null,
            loadVehicleFromQuote: null,
            affiliateUserId: null
        },
        savedAddresses: [
            {
                manual: false,
                streetAddress: null,
                value: 0,
            }
        ],
        savedEmploymentAddresses: [
            {
                manual: false,
                streetAddress: null,
                value: 0,
            }
        ],
        existingApp: null,
        loanOption: null,
        fullAddresses: [
            {
                index: 0,
                address: null,
                town: null,
                hideAddressLookup: false
            }
        ],
        fullEmploymentAddresses: [
            {
                index: 0,
                address: null,
                town: null,
                hideAddressLookup: false
            }
        ],
        form: {
            financeDetailsCompleted: false,
            drivinglicenceCompleted: false,
            submissionTypeCompleted: false,
            maritalStatusCompleted: false,
            employmentStatusCompleted: false,
            employmentStatusOtherClicked: false,
            addressStatusCompleted: false,
            personalDetailsCompleted: false,
            carFinanceDetailsCompleted: false,
            bankDetailsCompleted: false,
            bankLengthCompleted: false,
            referralId: null,
            riskBand: null,
            affiliateUserId: null,
            clickId: null,
            loanTypeId: null,
            vehicleTypeId: CanCoutinuButtonHelper.canCoutinue() ? null : 40, // default to car
            loanAmount: DEFAULT_VALUE.loanAmount,
            titleId: null,
            firstName: null,
            lastName: null,
            email: null,
            contactNumber: null,
            dateOfBirth: null,
            maritalStatusId: CanCoutinuButtonHelper.canCoutinue() ? null : 24, //default to single
            drivingLicenceTypeId: CanCoutinuButtonHelper.canCoutinue() ? null : 35, // default to full
            addresses: [
                {
                    subBuilding: null,
                    buildingName: null,
                    buildingNumber: null,
                    locality: null,
                    town: null,
                    postcode: null,
                    yearsAtAddress: null,
                    monthsAtAddress: null,
                    residentialStatusId: CanCoutinuButtonHelper.canCoutinue() ? null : 32, // default to homeowner
                    county: null,
                    street: null,
                    flatNumber: null // doesn't seem to be used
                }
            ],
            employments: [
                {
                    occupation: null,
                    employerName: null,
                    employerTown: null,
                    statusId: CanCoutinuButtonHelper.canCoutinue() ? null : 9, // default to full time
                    yearsAtEmployer: null,
                    monthsAtEmployer: null,
                    subBuilding: null,
                    buildingName: null,
                    buildingNumber: null,
                    locality: null,
                    postcode: null,
                    county: null,
                    street: null,
                    flatNumber: null // doesn't seem to be used
                }
            ],
            monthlyBudget: null,
            netMonthlyIncome: null,
            banks: [
                {
                    bankName: null,
                    accountName: null,
                    accountNumber: null,
                    sortCode: null,
                    yearsAtBank: null,
                    monthsAtBank: null
                }
            ],
            vehicleFinance: {
                term: null,
                deposit: null,
                partExchangeValue: null,
                partExchangeSettlement: null,
                estimatedAnnualMileage: null,
            },
            isPromoEmail: false,
            isPromoSms: false,
            isPromoTelephone: false,
            isPromoWhatsApp: false,
            IsShareInfo: false,
            agreedTermsAndConditions: false,
            remoteAddress: null,
            userAgent: null,
            deviceContextId: null,
            submittedBy: "Carfinance.Application.Form.Prototype",
            urlReferrer: null, // set by domain
            appliedDate: null,
            IsCompleted: false,
            utmParameters: {
                agentPublicId: null,
                campaign: null,
                id: null,
                content: null,
                gclid: null,
                fbclid: null,
                medium: null,
                mkwid: null,
                referrerApplicationId: null,
                sid: null,
                source: null,
                term: null,
                type: null,
                visitorId: null,
            },
            vehicle: {
                vehicleId: null,
                colour: null,
                fuelType: null,
                make: null,
                mileage: null,
                model: null,
                price: null,
                registrationYear: null,
                transmission: null,
                vrm: null,
                imageUrl: null,
                dealerInfoUrl: null,
                vdpUrl: null
            }
        },
        fullAppSuccess: false,
        membersURL: null,
        duplicateApp: false,
        lockDown: false,
        isProduction: false,
        noTransition: false,
        noBankDetails: false,
        noPx: false,
        isDealer: false,
        dealer: null,
        dealerName: null,
        dealerAffiliateUserId: null
    },
    mutations: {
        UPDATE_FORM_VALUE(state, data) {
            let obj = state
            let value = data[0]
            let name = data[1]

            if (name === 'form.firstName') {
                value = value ? value.charAt(0).toUpperCase() + value.slice(1) : value
            }

            if (name === 'form.lastName') {
                value = value ? value.charAt(0).toUpperCase() + value.slice(1) : value
            }

            _.set(obj, name, value)
        },
        GET_STORE_FROM_SESSION_STORAGE(state) {

            let version = process.env.VUE_APP_VERSION
            let store = JSON.parse(sessionStorage.getItem('store'))
            if (store) {
                if (store.version === version) {
                    this.replaceState(
                        Object.assign(state, JSON.parse(sessionStorage.getItem('store')))
                    )
                }
            }
        },
        SET_APPLIED_DATE(state) {
            let now = new Date();
            state.appliedDate = now.toJSON();
            state.form.appliedDate = now.toJSON();
        },
        SET_REFERRER_URL(state) {
            state.form.urlReferrer = location.host
        },
        SET_GUID(state, data) {
            state.GUID = data
        },
        SET_AUTH_TOKEN(state, data) {
            state.token = {...data,  'tokenUpdateTime': Math.floor(Date.now() / 1000) }
        },
        TOGGLE_MANUAL_ADDRESS(state, index) {
            state.savedAddresses[index].manual = !state.savedAddresses[index].manual
        },
        TOGGLE_MANUAL_EMPLOYMENT_ADDRESS(state, index) {
            state.savedEmploymentAddresses[index].manual = !state.savedEmploymentAddresses[index].manual
        },
        SET_IS_MONTHLY_BUDGET_ALLOWED(state, data) {
            state.isMonthlyBudgetAllowed = data
        },
        SET_SHOW_LOAN_AMOUNT(state, data) {
            state.showLoanAmount = data
        },
        SET_IP_ADDRESS(state, data) {
            state.form.remoteAddress = data
        },
        SET_UTM_PARAMETERS(state, data) {
            state.form.utmParameters = data
        },
        SET_IS_PRODUCTION(state, data) {
            state.isProduction = data
        },
        SET_HOST(state, data) {
            state.host = data
        },
        SET_IS_DEALER(state, data) {
            state.isDealer = data
        },
        SET_DEALER_NAME(state, data) {
            state.dealerName = data
        },
        SET_DEALER_AID(state, data) {
            state.dealerAffiliateUserId = data
        },
        SET_SUBDOMAIN(state, data) {
            state.subdomain = data
        },
        SET_LOAN_AMOUNT(state, data) {
            state.form.loanAmount = data
        },
        SET_FIRST_NAME(state, data) {
            state.form.firstName = data
        },
        SET_LAST_NAME(state, data) {
           
            state.form.lastName = data
        },
        SET_EMAIL_ADDRESS(state, data) {
            state.form.email = data
        },
        SET_CONTACT_NUMBER(state, data) {
            state.form.contactNumber = data
        },
        SET_TERM(state, data) {
            state.form.vehicleFinance.term = data
        },
        SET_DEPOSIT(state, data) {
            state.form.vehicleFinance.deposit = data
        },
        SET_PX_VALUE(state, data) {
            state.form.vehicleFinance.partExchangeValue = data
        },
        SET_PX_SETTLEMENT(state, data) {
            state.form.vehicleFinance.partExchangeSettlement = data
        },
        SET_ANNUAL_MILEAGE(state, data) {
            state.form.vehicleFinance.estimatedAnnualMileage = data
        },
        SET_NO_TRANSITION(state, data) {
            state.noTransition = data;
        },
        SET_NO_BANK_DETAILS(state, data) {
            state.noBankDetails = data;
        },
        SET_NO_PX(state, data) {
            state.noPx = data;
        },
        SET_AFFILIATE_USER_ID(state, data) {
            state.form.affiliateUserId = data ? Number(data) : null;
        },
        SET_CLICK_ID(state, data) {
            state.form.clickId = data ? data : null;
        },
        SET_APP_ID(state, data) {
            state.applicationId = data;
            if (state.form) state.form.referralId = data;
        },
        SET_LOAN_TYPE_ID(state, data) {
            state.form.loanTypeId = data && Number(data) > 0 ? Number(data) : null;
        },
        SET_SUBMISSION_TYPE_ID(state, data) {
            state.form.submissionTypeId = data && Number(data) > 0 ? Number(data) : null;
        },
        SET_MONTHLY_BUDGET(state, data) {
            state.form.monthlyBudget = data && Number(data) > 0 ? Number(data) : null;
        },
        SET_VEHICLE_TYPE(state, data) {
            state.form.vehicleTypeId = data && Number(data) > 0 ? Number(data) : null;
        },
        SET_VEHICLE(state, data) {
            state.form.vehicle = data
        },
        SET_VEHICLE_ID(state, data) {
            state.vehicleId = data
        },
        SET_VRM(state, data) {
            state.vrm = data
        },
        SET_CLOUDINARY_VEHICLE_IMAGE_URL(state, data) {
            state.cloudinaryVehicleImageUrl = data
        },
        SET_AFFILIATE_CONFIG(state, data) {
            state.affiliateConfig = data
        },
        SET_DEALER_CONFIG(state, data) {
            state.dealerConfig = data
        },
        SET_EXISTING_APP(state, data) {
            state.existingApp = data
        },
        SET_LOAN_OPTION(state, data) {
            state.loanOption = data;
        },
        SET_FULL_ADDRESS(state, data) {
            let index = state.fullAddresses.findIndex(i => i.index === data.index)
            if (index !== -1) {
                Vue.set(state.fullAddresses, index, data)
            } else {
                state.fullAddresses.push(data)
            }
        },
        SET_FULL_EMPLOYMENT_ADDRESS(state, data) {
            let index = state.fullEmploymentAddresses.findIndex(i => i.index === data.index)
            if (index !== -1) {
                Vue.set(state.fullEmploymentAddresses, index, data)
            } else {
                state.fullEmploymentAddresses.push(data)
            }
        },
        SET_ADDRESS_CONFIRMED(state, index) {
            let idx = state.fullAddresses.findIndex(i => i.index === index)
            this.state.fullAddresses[idx].hideAddressLookup = !this.state.fullAddresses[idx].hideAddressLookup
        },
        SET_EMPLOYMENT_ADDRESS_CONFIRMED(state, index) {
            let idx = state.fullEmploymentAddresses.findIndex(i => i.index === index)
            this.state.fullEmploymentAddresses[idx].hideAddressLookup = !this.state.fullEmploymentAddresses[idx].hideAddressLookup
        }
    },
    actions: {
        setIsAddressConfirmed(state, index) {
            this.commit('SET_ADDRESS_CONFIRMED', index)
        },
        setIsEmploymentAddressConfirmed(state, index) {
            this.commit('SET_EMPLOYMENT_ADDRESS_CONFIRMED', index)
        },
        updateFormValue(state, data) {
            this.commit('UPDATE_FORM_VALUE', data)
        },
        setAppliedDate(app) {
            if (!app.state.form.appliedDate) {
                this.commit('SET_APPLIED_DATE')
            }
        },
        setReferrerURL(app) {
            if (!app.state.form.urlReferrer) {
                this.commit('SET_REFERRER_URL')
            }
        },
        toggleManualAddress(state, index) {
            this.commit('TOGGLE_MANUAL_ADDRESS', index)
        },
        toggleManualEmploymentAddress(state, index) {
            this.commit('TOGGLE_MANUAL_EMPLOYMENT_ADDRESS', index)
        },
        async updateAuthToken(app) {
            const clientTokenUrl = app.state.isProduction ? URLS.production.clientTokenUrl : URLS.replica.clientTokenUrl;
            try {
                const response = await axios.get(clientTokenUrl);
                this.commit('SET_AUTH_TOKEN', response.data);
                return response;
            } catch (error) {
                throw error;
            }
        },
        setGUID() {
            if (!this.state.GUID) { // if there's not already a GUID in the state object, generate one

                let e = new Date().getTime()
                e += performance.now()

                let id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (t) {
                    let i = (e + 16 * Math.random()) % 16 | 0;
                    return e = Math.floor(e / 16),
                        ("x" === t ? i : 3 & i | 8).toString(16)
                })

                this.commit('SET_GUID', id)
            }
        },
        setDeviceInfo(app) {

            let This = this
            let tokenString = 'Bearer ' + app.state.token.authenticationToken
            let headersObj = {"Authorization": tokenString}

            const delegateFn = () => {
                const applicationApiFullUrl = app.state.isProduction ? URLS.production.applicationApiFullUrl : URLS.replica.applicationApiFullUrl;

                axios({
                    method: 'GET',
                    url: applicationApiFullUrl + '/api/Client/GetIPAddress',
                    headers: headersObj
                })
                    .then(function (response) {
                        This.commit('SET_IP_ADDRESS', response.data)
                    })
                    .catch(() => {

                    })

                let userAgent = navigator.userAgent
                let data = [userAgent, 'form.userAgent']
                this.commit('UPDATE_FORM_VALUE', data)
            };

            delegateFn();
        },
        setClickId() {
            let clickId = RequestHelper.getQueryVariable('clickid')

            if (clickId) {
                this.commit('SET_CLICK_ID', clickId)
            }
        },
        setLoanOption(state, data) {
            this.commit('SET_LOAN_OPTION', data)
        },
        setIsDealer() {
            const daid = RequestHelper.getQueryVariable('daid');
            const dname = RequestHelper.getQueryVariable('dname');

            if (daid) {
                this.commit('SET_IS_DEALER', true)
                this.commit('SET_DEALER_AID', Number(daid));
                this.commit('SET_DEALER_NAME', dname ? dname : '');
            }
        },
        setUTMParams(state) {
            let obj = {}

            let affiliateUserId = RequestHelper.getQueryVariable('aid') || RequestHelper.getQueryVariable('daid')
            const daid = RequestHelper.getQueryVariable('daid');

            if (daid) {
                this.commit('SET_IS_DEALER', true)
            }
            

            if (!affiliateUserId) {
                if (state.state.affiliateConfig && state.state.affiliateConfig.affiliateUserId) {
                    affiliateUserId = state.state.affiliateConfig.affiliateUserId
                } else if (state.state.dealerConfig && state.state.dealerConfig.affiliateUserId) {
                    affiliateUserId = state.state.dealerConfig.affiliateUserId
                } else {
                    let host = LocationHelper.getHost();

                    const affiliateConfig = AFFILIATES[host]

                    if (affiliateConfig) {
                        affiliateUserId = affiliateConfig.affiliateUserId;
                    }

                    const dealerConfig = DEALERS[host]

                    if (dealerConfig) {
                        affiliateUserId = dealerConfig.affiliateUserId;
                    }
                }
            }

            if (document.cookie.split(';').filter((item) => item.trim().startsWith('cf247-utm=')).length) {
                const utmCookie = CookieHelper.get('cf247-utm')
                let cookie = JSON.parse(utmCookie)

                obj.source = cookie['UtmSource'] || RequestHelper.getQueryVariable('utm_source')
                obj.medium = cookie['UtmMedium'] || RequestHelper.getQueryVariable('utm_medium') || RequestHelper.getQueryVariable('medium')
                obj.term = cookie['UtmTerm'] || RequestHelper.getQueryVariable('utm_term') || RequestHelper.getQueryVariable('term')
                obj.content = cookie['UtmContent'] || RequestHelper.getQueryVariable('utm_content') || RequestHelper.getQueryVariable('content')
                obj.campaign = cookie['UtmCampaign'] || RequestHelper.getQueryVariable('utm_campaign') || RequestHelper.getQueryVariable('campaign')
                obj.type = cookie['UtmType'] || RequestHelper.getQueryVariable('utm_type') || RequestHelper.getQueryVariable('type')
                obj.sid = state.form && state.form.utmParameters ? state.form.utmParameters.sid : null
                obj.mkwid = cookie['Mkwid'] || RequestHelper.getQueryVariable('mkwid')
                obj.gclid = cookie['Gclid'] || RequestHelper.getQueryVariable('gclid')
                obj.fbclid = cookie['Fbclid'] || RequestHelper.getQueryVariable('fbclid')
                obj.agentPublicId = state.form && state.form.utmParameters ? state.form.utmParameters.agentPublicId : null
                obj.id = RequestHelper.getQueryVariable('id') || null;
                obj.referrerApplicationId = cookie['Rai'] || RequestHelper.getQueryVariable('rai')

                if ((cookie['Aid'] || affiliateUserId) && !daid)
                    affiliateUserId = cookie['Aid'] ? cookie['Aid'] : affiliateUserId

                if (cookie['Daid'] || daid)
                    affiliateUserId = cookie['Daid'] ? cookie['Daid'] : daid

                if (typeof window.ga !== 'undefined' && window.ga !== null) {
                    window.ga(function (tracker) {
                        let googleClientId = tracker.get('clientId');
                        if (googleClientId || obj.gclid) {

                            const attributionUrl = state.isProduction ? URLS.production.attributionApiUrl : URLS.replica.attributionApiUrl;

                            let tokenString = 'Bearer ' + state.token['authenticationToken']
                            let headersObj = {"Authorization": tokenString}

                            axios({
                                method: 'POST',
                                url: attributionUrl + '/api/Attribution',
                                headers: headersObj,
                                data: {
                                    'guid': state.GUID,
                                    'googleClientId': googleClientId,
                                    'gclid': obj.gclid
                                }
                            }).catch(error => {
                                window.console.error(error.message)
                            })
                        }
                    });
                }

                this.commit('SET_UTM_PARAMETERS', obj)
                this.commit('SET_AFFILIATE_USER_ID', affiliateUserId)
            } else {
                const utmExpId = RequestHelper.getQueryVariable('utm_expid')

                if (utmExpId) {
                    const utmReferrer = RequestHelper.getQueryVariable('utm_referrer')

                    const referrarUri = decodeURIComponent(decodeURIComponent(utmReferrer))

                    obj.source = AffiliatesHelper.checkHostForUtmSource() ? location.host : RequestHelper.getQueryVariableFromUrl(referrarUri, 'utm_source')
                    obj.medium = RequestHelper.getQueryVariableFromUrl(referrarUri, 'utm_medium') || RequestHelper.getQueryVariableFromUrl(referrarUri, 'medium')
                    obj.term = RequestHelper.getQueryVariableFromUrl(referrarUri, 'utm_term') || RequestHelper.getQueryVariableFromUrl(referrarUri, 'term')
                    obj.content = RequestHelper.getQueryVariableFromUrl(referrarUri, 'utm_content') || RequestHelper.getQueryVariableFromUrl(referrarUri, 'content')
                    obj.campaign = RequestHelper.getQueryVariableFromUrl(referrarUri, 'utm_campaign') || RequestHelper.getQueryVariableFromUrl(referrarUri, 'campaign')
                    obj.type = RequestHelper.getQueryVariableFromUrl(referrarUri, 'utm_type') || RequestHelper.getQueryVariableFromUrl(referrarUri, 'type')
                    obj.sid = RequestHelper.getQueryVariableFromUrl(referrarUri, 'utm_sid')
                    obj.mkwid = RequestHelper.getQueryVariableFromUrl(referrarUri, 'mkwid')
                    obj.agentPublicId = RequestHelper.getQueryVariableFromUrl(referrarUri, 'advisor')
                    obj.id = obj.mkwid ? RegExHelper.extract(obj.mkwid, /campaignid\|(\d+)/i) : null
                    obj.referrerApplicationId = RequestHelper.getQueryVariableFromUrl(referrarUri, 'rai')
                    obj.gclid = RequestHelper.getQueryVariableFromUrl(referrarUri, 'gclid')
                    obj.fbclid = RequestHelper.getQueryVariableFromUrl(referrarUri, 'fbclid')

                    if (RequestHelper.getQueryVariableFromUrl(referrarUri, 'aid'))
                        affiliateUserId = RequestHelper.getQueryVariableFromUrl(referrarUri, 'aid')

                    if (RequestHelper.getQueryVariableFromUrl(referrarUri, 'daid'))
                        affiliateUserId = RequestHelper.getQueryVariableFromUrl(referrarUri, 'daid')

                    this.commit('SET_UTM_PARAMETERS', obj)
                    this.commit('SET_AFFILIATE_USER_ID', affiliateUserId)
                } else {
                    obj.source = AffiliatesHelper.checkHostForUtmSource() ? location.host : RequestHelper.getQueryVariable('utm_source')
                    obj.medium = RequestHelper.getQueryVariable('utm_medium') || RequestHelper.getQueryVariable('medium')
                    obj.term = RequestHelper.getQueryVariable('utm_term') || RequestHelper.getQueryVariable('term')
                    obj.content = RequestHelper.getQueryVariable('utm_content') || RequestHelper.getQueryVariable('content')
                    obj.campaign = RequestHelper.getQueryVariable('utm_campaign') || RequestHelper.getQueryVariable('campaign')
                    obj.type = RequestHelper.getQueryVariable('utm_type') || RequestHelper.getQueryVariable('type')
                    obj.sid = RequestHelper.getQueryVariable('utm_sid')
                    obj.mkwid = RequestHelper.getQueryVariable('mkwid')
                    obj.agentPublicId = RequestHelper.getQueryVariable('advisor')
                    obj.id = obj.mkwid ? RegExHelper.extract(obj.mkwid, /campaignid\|(\d+)/i) : null
                    obj.referrerApplicationId = RequestHelper.getQueryVariable('rai')
                    obj.gclid = RequestHelper.getQueryVariable('gclid')
                    obj.fbclid = RequestHelper.getQueryVariable('fbclid')

                    this.commit('SET_UTM_PARAMETERS', obj)
                    this.commit('SET_AFFILIATE_USER_ID', affiliateUserId)
                }
            }
        },
        setAppId() {
            const appId = RequestHelper.getQueryVariable('appid')
            this.commit('SET_APP_ID', appId)
        },
        setVehicleType() {
            const vehicle_type = RequestHelper.getQueryVariable('vehicle_type')

            if (vehicle_type) {
                const index = Object.keys(VehicleType).findIndex(v => v.toUpperCase() === vehicle_type.toUpperCase().trim());

                const vehicleTypeId = index > -1 ? Object.values(VehicleType)[index] : VehicleType.Car;

                this.commit('SET_VEHICLE_TYPE', vehicleTypeId);
            }
        },
        setLoanTypeId() {
            if (!isNaN(RequestHelper.getQueryVariable('loanTypeId'))) {
                const loanTypeId = Number(RequestHelper.getQueryVariable('loanTypeId'))
                this.commit('SET_LOAN_TYPE_ID', loanTypeId)
            }
        },
        setSubmissionTypeId() {
            if (!isNaN(RequestHelper.getQueryVariable('submissionTypeId'))) {
                const submissionTypeId = Number(RequestHelper.getQueryVariable('submissionTypeId'))
                this.commit('SET_SUBMISSION_TYPE_ID', submissionTypeId)
            }
        },
        setMonthlyBudget() {
            if (!isNaN(RequestHelper.getQueryVariable('monthlyBudget'))) {
                const monthlyBudget = Number(RequestHelper.getQueryVariable('monthlyBudget'))
                this.commit('SET_MONTHLY_BUDGET', monthlyBudget)
            }
        },
        setExistingApp(state, data) {
            this.commit('SET_EXISTING_APP', data)
        },
        setVehicle(state, data) {
            this.commit('SET_VEHICLE_ID', RequestHelper.getQueryVariable('vehicleid') ? RequestHelper.getQueryVariable('vehicleid') : null)
            this.commit('SET_VRM', RequestHelper.getQueryVariable('vrm') ? RequestHelper.getQueryVariable('vrm') : null);

            let obj = {}

            if (LocationHelper.getHost() === 'pistonheads') {

                const advertId = RequestHelper.getQueryVariable('advertId');

                PistonHeadsCarAdvertService.getCarAdvert(data, advertId)
                    .then((response) => {

                        if (response && response.data) {
                            obj.make = response.data.make;
                            obj.model = response.data.model;
                            obj.price = response.data.price;
                            obj.vrm = response.data.vrm.toUpperCase();
                            obj.year = response.data.year;
                            obj.mileage = response.data.mileage;
                            obj.fuel = response.data.fuel;
                            obj.transmission = response.data.transmission;
                            obj.colour = response.data.colour;
                            obj.vdpUrl = response.data.vdpUrl;
                            obj.imageUrl = response.data.thumbnailImage;
                            obj.dealerInfoUrl = response.data.dealerInfoUrl;
                        }

                        this.commit('SET_VEHICLE', obj)
                    })
                    .catch((error) => {
                        window.console.error(error.message)
                    });
            } else {
                const price = RequestHelper.getQueryVariable('price') || RequestHelper.getQueryVariable('amount');
                const mileage = RequestHelper.getQueryVariable('mileage');

                obj.vrm = RequestHelper.getQueryVariable('vrm');
                
                if (obj.vrm) {
                    obj.vrm = obj.vrm.toUpperCase()
                }
                obj.year = RequestHelper.getQueryVariable('year');
                obj.make = RequestHelper.getQueryVariable('make');
                obj.model = RequestHelper.getQueryVariable('model');
                obj.price = price ? Number(price) : null;
                obj.mileage = mileage ? Number(mileage) : null;
                obj.fuel = RequestHelper.getQueryVariable('fuel');
                obj.transmission = RequestHelper.getQueryVariable('transmission');
                obj.colour = RequestHelper.getQueryVariable('colour');
                obj.imageUrl = RequestHelper.getQueryVariable('imageurl');
                obj.vdpUrl = RequestHelper.getQueryVariable('vdpurl');
                obj.dealerInfoUrl = RequestHelper.getQueryVariable('dealerinfourl');

                if (obj.imageUrl && obj.imageUrl.indexOf('cloudinary') === -1) {
                    const cloudinaryVehicleImageUrl = CloudinaryService.getCloudinaryImageUrl(obj.imageUrl);
                    this.commit('SET_CLOUDINARY_VEHICLE_IMAGE_URL', cloudinaryVehicleImageUrl)
                }

                this.commit('SET_VEHICLE', obj)
            }
            
        },
        setCloudinaryVehicleImageUrl(state, data) {
            this.commit('SET_CLOUDINARY_VEHICLE_IMAGE_URL', data)
        },
        setAffiliateConfig(state, data) {
            this.commit('SET_AFFILIATE_CONFIG', data)
        },
        setDealerConfig(state, data) {
            this.commit('SET_DEALER_CONFIG', data)
        },
        setIsProduction(state, data) {
            this.commit('SET_IS_PRODUCTION', data)
        },
        setHost(state, data) {
            this.commit('SET_HOST', data)
        },
        setSubdomain(state, data) {
            this.commit('SET_SUBDOMAIN', data)
        },
        setLoanAmount() {
            const loanCookie = CookieHelper.get('loanCookie')

            let loanAmount = DEFAULT_VALUE.loanAmount

            if (loanCookie) {
                let vars = loanCookie.split("&");
                let query_string = {};
                for (let i = 0; i < vars.length; i++) {
                    let pair = vars[i].split("=");
                    query_string[pair[0]] = decodeURIComponent(pair[1]);
                }

                loanAmount = parseInt(query_string['LoanAmount'])
            }

            loanAmount = RequestHelper.getQueryVariable('amount') && !isNaN(RequestHelper.getQueryVariable('amount')) ? RequestHelper.getQueryVariable('amount') : loanAmount
            loanAmount = RequestHelper.getQueryVariable('amount_uswitch') && !isNaN(RequestHelper.getQueryVariable('amount_uswitch')) ? RequestHelper.getQueryVariable('amount_uswitch') : loanAmount
            loanAmount = RequestHelper.getQueryVariable('loan-amount-prepop') && !isNaN(RequestHelper.getQueryVariable('loan-amount-prepop')) ? RequestHelper.getQueryVariable('loan-amount-prepop') : loanAmount

            this.commit('SET_SHOW_LOAN_AMOUNT', RequestHelper.getQueryVariable('loan-amount-prepop') == null || isNaN(RequestHelper.getQueryVariable('loan-amount-prepop')));

            if (this.isMonthlyBudgetAllowed) {
                loanAmount = RequestHelper.getQueryVariable('monthlyBudget') && !isNaN(RequestHelper.getQueryVariable('monthlyBudget')) ? Math.round(RequestHelper.getQueryVariable('monthlyBudget') * 60) : loanAmount
            }
            this.commit('SET_LOAN_AMOUNT', loanAmount)
        },
        setPrepopData() {
            const firstName = RequestHelper.getQueryVariable('firstName') ? RequestHelper.getQueryVariable('firstName') : null
            this.commit('SET_FIRST_NAME', firstName)

            const lastName = RequestHelper.getQueryVariable('lastName') ? RequestHelper.getQueryVariable('lastName') : null
            this.commit('SET_LAST_NAME', lastName)

            const email = RequestHelper.getQueryVariable('email')  ? RequestHelper.getQueryVariable('email') : null
            this.commit('SET_EMAIL_ADDRESS', email)

            const contactNumber = RequestHelper.getQueryVariable('contactNumber') ? RequestHelper.getQueryVariable('contactNumber') : null
            this.commit('SET_CONTACT_NUMBER', contactNumber)

            const term = RequestHelper.getQueryVariable('term') && !isNaN(RequestHelper.getQueryVariable('term')) ? Number(RequestHelper.getQueryVariable('term')) : null
            this.commit('SET_TERM', term)

            const deposit = RequestHelper.getQueryVariable('deposit') && !isNaN(RequestHelper.getQueryVariable('deposit')) ? Number(RequestHelper.getQueryVariable('deposit')) : null
            this.commit('SET_DEPOSIT', deposit)

            const pxValue = RequestHelper.getQueryVariable('pxValue') && !isNaN(RequestHelper.getQueryVariable('pxValue')) ? Number(RequestHelper.getQueryVariable('pxValue')) : null
            this.commit('SET_PX_VALUE', pxValue)

            const pxSettlement = RequestHelper.getQueryVariable('pxSettlement') && !isNaN(RequestHelper.getQueryVariable('pxSettlement')) ? Number(RequestHelper.getQueryVariable('pxSettlement')) : null
            this.commit('SET_PX_SETTLEMENT', pxSettlement)

            const annualMileage = RequestHelper.getQueryVariable('annualMileage') && !isNaN(RequestHelper.getQueryVariable('annualMileage')) ? Number(RequestHelper.getQueryVariable('annualMileage')) : null
            this.commit('SET_ANNUAL_MILEAGE', annualMileage)

            const noTransition = RequestHelper.getQueryVariable('noTransition') ? RequestHelper.getQueryVariable('noTransition') : null
            this.commit('SET_NO_TRANSITION', noTransition ? true : false)

            const noBankDetails = RequestHelper.getQueryVariable('noBankDetails') ? RequestHelper.getQueryVariable('noBankDetails') === 'true' ? true : false : null
            this.commit('SET_NO_BANK_DETAILS', noBankDetails ? true : false)

            const noPx = RequestHelper.getQueryVariable('noPx') ? RequestHelper.getQueryVariable('noPx') === 'true' ? true : false : null
            this.commit('SET_NO_PX', noPx ? true : false)
        },
        setFullAddress(state, data) {
            if( !data.isFromPrepop  ) {
                const currentAddress = data
                const town = currentAddress.town
                let fullAddress = currentAddress.address

                const addressSplit = fullAddress.split(' ')
                const unFormattedTown = addressSplit.splice(addressSplit.length - 1, 1).join('')

                const regex = new RegExp(unFormattedTown, 'gi')
                fullAddress = fullAddress.replace(regex, `${town}.`)

                const postcode = addressSplit.splice(0, 2).join(' ')
                fullAddress = `${fullAddress.replace(postcode, '')} ${postcode}`

                data.address = fullAddress
            }
            this.commit('SET_FULL_ADDRESS', data)
        },
        setFullEmploymentAddress(state, data) {
            if( !data.isFromPrepop  ) {
                const currentAddress = data
                const town = currentAddress.town
                let fullAddress = currentAddress.address

                const addressSplit = fullAddress.split(' ')
                const unFormattedTown = addressSplit.splice(addressSplit.length - 1, 1).join('')

                const regex = new RegExp(unFormattedTown, 'gi')
                fullAddress = fullAddress.replace(regex, `${town}.`)

                const postcode = addressSplit.splice(0, 2).join(' ')
                fullAddress = `${fullAddress.replace(postcode, '')} ${postcode}`

                data.address = fullAddress
            }
            this.commit('SET_FULL_EMPLOYMENT_ADDRESS', data)
        },
    },
    getters
:
{
    progress: state => {
        let result = state.progress;
        return {
            validated: 0, // placeholder value until used to show validated progress
            complete: result
        }
    },
    fromGetMyQuote: state => {
        return state.fromGetMyQuote;
    },
    pageName: state => {
        return state.pageName;
    },
        totalAddressHistory
:
    state => {
        let obj = state.form
        let array = state.form.addresses
        let totalTime = 0
        if (array) {
            array.forEach(function (el, index) {
                let years = _.get(obj, 'addresses[' + index + '].yearsAtAddress')
                let months = _.get(obj, 'addresses[' + index + '].monthsAtAddress')
                let monthYears = months / 12
                let time = years + monthYears
                totalTime += time
            })
        }
        return totalTime
    },
        indexAddressHistory
:
    state => index => {
        let years = state.form.addresses[index].yearsAtAddress
        let months = state.form.addresses[index].monthsAtAddress
        let monthYears = months / 12
        let time = years + monthYears
        return time
    },
        indexAddressYears
:
    state => index => {
        let years = state.form.addresses[index].yearsAtAddress
        return years
    },
        indexAddressMonths
:
    state => index => {
        let months = state.form.addresses[index].monthsAtAddress
        return months
    },
        totalAddressCount
:
    state => {
        let addressArray = state.form.addresses
        if (addressArray) {
            let count = addressArray.length
            return count
        }
        return 0
    },
    totalEmploymentCount
        :
        state => {
            let addressArray = state.form.employments
            if (addressArray) {
                let count = addressArray.length
                return count
            }
            return 0
        },
        totalEmploymentHistory
:
    state => {
        let obj = state.form
        let array = state.form.employments
        let totalTime = 0
        if (array) {
            array.forEach(function (el, index) {
                let years = _.get(obj, 'employments[' + index + '].yearsAtEmployer')
                let months = _.get(obj, 'employments[' + index + '].monthsAtEmployer')
                let monthYears = months / 12
                let time = years + monthYears
                totalTime += time
            })
            return totalTime
        }
        return 0
    },
        indexEmploymentHistory
:
    state => index => {
        let years = state.form.employments[index].yearsAtEmployer
        let months = state.form.employments[index].monthsAtEmployer
        let monthYears = months / 12
        let time = years + monthYears
        return time
    },
    indexBankHistory
:
    state => index => {
        let years = state.form.banks[index].yearsAtBank
        let months = state.form.banks[index].monthsAtBank
        let monthYears = months / 12
        let time = years + monthYears
        return time
    },
        indexEmploymentYears
:
    state => index => {
        let years = state.form.employments[index].yearsAtEmployer
        return years
    },
        indexEmploymentMonths
:
    state => index => {
        let months = state.form.employments[index].monthsAtEmployer
        return months
    },
    indexBankYears
:
    state => index => {
        let years = state.form.banks[index].yearsAtBank
        return years
    },
    indexBankMonths
:
    state => index => {
        let months = state.form.banks[index].monthsAtBank
        return months
    },
        employmentStatus
:
    state => index => {
        return state.form.employments[index].statusId
    },
        employmentsCount
:
    state => {
        let addressArray = state.form.employments
        let count = addressArray.length
        return count
    },
        isProduction
:
    state => {
        return state.isProduction
    },
        host
:
    state => {
        return state.host
    },
        subdomain
:
    state => {
        return state.subdomain
    },
        getAffiliateConfig
:
    state => {
        return state.affiliateConfig
    },
        getFullAddress
:
    state => index => {
        return state.fullAddresses[index]
    },
        getEmployerName
:
    state => index => {
        return state.form.employments[index].employerName
    },
        any
:
    state => data => {
        let obj = state
        let result = _.get(obj, data)
        return result
    }
}
})
