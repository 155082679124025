<template>
  <section v-if="this.isBannerVisiable()">
    <div class="vehicle-banner"  >
      <div v-if="this.$store.state.form.vehicle.imageUrl" class="container__image" :style="{ 'backgroundImage': `url(${this.$store.state.form.vehicle.imageUrl})` }" >

      </div>
      <div class="container__text" :style=" this.adjustTextHeight() ? { height: 'auto' } : null ">
        <div class="container__text__table">
          <div class="container__text__table__row">
            <div class="container__text__table__row__cell">
              <div v-if="this.$store.state.form.vehicle.make" style="font-weight: bold">{{ this.$store.state.form.vehicle.make }}</div>
              <div v-if="this.$store.state.form.vehicle.model">{{ this.$store.state.form.vehicle.model }}</div>
              <div style="font-weight: bold" v-if="this.$store.state.form.vehicle.price && this.$store.state.form.vehicle.price > 0">£{{ this.formatAsMoney(this.$store.state.form.vehicle.price) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import accounting from "accounting-js";

export default {
  data() {
    return {
      isPrivacyVisible: false,
    }
  },
  components: {},
  methods: {
    isBannerVisiable: function() {
          if (this.$store.state.form.vehicle && this.$store.state.form.vehicle.vrm && this.$store.state.form.vehicle.make &&
          (this.$store.state.form.vehicle.model || 
          this.$store.state.form.vehicle.price ||
          this.$store.state.form.vehicle.imageUrl ) ) {
        return true;
      }
      return false;
    },
    adjustTextHeight: function() {
      if( this.$store.state.form.vehicle && this.$store.state.form.vehicle.imageUrl ) {
        return false;
      }
      return true;
    },

    formatAsMoney: function (value) {
      return accounting.formatMoney(value, {
        symbol: '',
        precision: 0,
        thousand: ',',
        decimal: '.'
      }).replace('.00', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.vehicle-banner{
  text-align: center;
}
.container {
  padding-top: 3px;

  &__image {

    display: inline-block;

    border-radius: 50%; 
    height: 150px;
    width: 150px;
    margin-right: 25px;
    background-size : cover;
    @media (max-height: 740px) {
      height: 80px;
      width: 80px;
    }
  }

  &__text {
    display: inline-block;

    height: 150px;
    font-size: 1.2rem;
    line-height: 1.22rem;

    text-align: left;

    @media (max-height: 740px) {
      height: 80px;
      width: 80px;
      font-size: 0.99rem;
    }

    &__table {
      display: table; 
      height: 100%;

      &__row {
        display: table-row;
         height: 100%;
        &__cell {
          display: table-cell;
          vertical-align: middle;
           height: 100%;

        }
      }
    }
  }

}

  section {
    border-bottom: 2px solid #ECECEC;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
</style>