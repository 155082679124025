<template>
  <div id="app" :class="theme">
    <Tealium />
    <ConsentManager />
    <AppForm />
  </div>
</template>

<script>
import {AFFILIATES, DEALERS} from './factories'
import {
  AgentService,
  VehicleService,
  PrePopApplicationService, 
  FeatureSwitchService
} from './services'
import {StateChangeEvent} from './events'
import {CookieHelper, LocationHelper, RequestHelper} from './helpers'
import { SegmentService } from "./services";
import { languages, defaultLocale } from '@/lang';
import AppForm from '@/views/AppForm.vue';
import Tealium from '@/components/Tealium.vue';
import ConsentManager from '@/components/ConsentManager.vue';

export default {
  name: 'app',
  metaInfo() {
    const externalScripts = this.externalScripts;
    return {
      title: 'Application Form',
      titleTemplate: '%s - CarFinance247.co.uk',
      htmlAttrs: {
        lang: 'en-GB'
      },
      script: externalScripts,
    }
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      membersURL: null,
      isProduction: process.env.NODE_ENV === "production",
      externalScripts: [],
      theme: '',
    }
  },
  computed: {
    state() {
      return this.$store.state
    }
  },
  methods: {
    loadAffiliateExternalScript() {
        let host = LocationHelper.getHost();

        if (host === 'confused') {
            this.externalScripts.push({ id: 'confused-gtm', src: '/affiliates/confused/gtm.js', async: false, defer: false });
        }
    },
    fetchExternalScripts() {
      let This = this

      let isProduction = This.isProduction;

      let host = LocationHelper.getHost();

      FeatureSwitchService.isFeatureEnabled(`optimize_${host}`, this.$store.state, isProduction).then((isEnabled) => {
        if (isEnabled) {
          window.console.log('anti-flicker snippet', true);
          this.externalScripts.push({ id: 'anti-flicker', src: '/anti-flicker.js', async: false, defer: false});
        } else {
          window.console.log('anti-flicker snippet', false);
        }
      });

      this.externalScripts.push({ id: 'gtm', src: '/gtm.js', async: false, defer: false });

      // if (isProduction) {
      //   this.externalScripts.push({ id: 'segment', src: '/segment-prod.js', async: false, defer: false });
      // } else {
      //   this.externalScripts.push({ id: 'segment', src: '/segment-dev.js', async: false, defer: false });
      // }

      this.loadAffiliateExternalScript();
    },
    setTheme(affiliateConfig) {
      if (affiliateConfig && typeof affiliateConfig.theme !== "undefined") {
        if (typeof affiliateConfig.themeProdEnabled !== "undefined") {
          const themeProdEnabled = affiliateConfig.themeProdEnabled;
         
          if (themeProdEnabled && this.isProduction || !this.isProduction) {
            this.theme = affiliateConfig.theme + (affiliateConfig.testB ? ' testB' : '');
          }
        } else {
          this.theme = affiliateConfig.theme  + (affiliateConfig.testB ? ' testB' : '');
        }

        // set theme-color
        if ( affiliateConfig.themeColor !== null && affiliateConfig.themeColor != '' ) {
          var reg=/^#([0-9a-f]{3}){1,2}$/i;
          if ( reg.test(affiliateConfig.themeColor) ) {
            var obj = document.querySelector('meta[name="theme-color"]');
            if(obj) {
              document.querySelector('meta[name="theme-color"]').setAttribute('content',  affiliateConfig.themeColor);
            }
          }
        }
      }
    }
  },
  beforeCreate() {
    this.$store.commit('GET_STORE_FROM_SESSION_STORAGE')
  },
  mounted() {
    let This = this

    this.fetchExternalScripts();

    this.$root.$i18n.locale = defaultLocale;

    let host = LocationHelper.getHost();

    if (host.startsWith('carcraft')) {
      this.setTheme(AFFILIATES['carcraft']);
    } else if (AFFILIATES[host] !== undefined) {
      this.setTheme(AFFILIATES[host]);
    } else if (DEALERS[host] !== undefined) {
      //this.setTheme(DEALERS[host]);
    }

    const langName = host.replace("-", "_");
    const lang = languages[langName];

    if (lang) {
      this.$root.$i18n.locale = langName;
    }

    this.$store.dispatch('setHost', host)
    this.$store.dispatch('setSubdomain', null)
    this.$store.dispatch('setAffiliateConfig', null)
    this.$store.dispatch('setDealerConfig', null)
    this.$store.dispatch('setIsDealer')

    if (host.startsWith('carcraft')) {
      const affiliateConfig = AFFILIATES['carcraft']
      this.$store.dispatch('setSubdomain', host)
      this.$store.dispatch('setAffiliateConfig', affiliateConfig)
    } else if (AFFILIATES[host] !== undefined) {
      const affiliateConfig = AFFILIATES[host]
      this.$store.dispatch('setSubdomain', host)
      this.$store.dispatch('setAffiliateConfig', affiliateConfig)
    } else if (this.$store.state.isDealer) {
        //this.$store.dispatch('setSubdomain', host)
    }

    this.$store.dispatch('setReferrerURL')
    this.$store.dispatch('setAppliedDate')
    this.$store.dispatch('setGUID')
    this.$store.dispatch('setIsProduction', This.isProduction)
    this.$store.dispatch('setLoanAmount')
    this.$store.dispatch('setPrepopData')
    this.$store.dispatch('setVehicle', This.isProduction)
    this.$store.dispatch('setAppId')
    this.$store.dispatch('setLoanTypeId')
    this.$store.dispatch('setSubmissionTypeId')
    this.$store.dispatch('setMonthlyBudget')
    this.$store.dispatch('setVehicleType')
    this.$store.dispatch('updateAuthToken').then(() => {
        This.$store.dispatch('setDeviceInfo')
    }).catch((error) => {
      throw error;
    }).then(() => {
      if (this.$store.state.applicationId && this.$store.state.affiliateConfig && this.$store.state.affiliateConfig.prePopulateForm) {
        PrePopApplicationService.prePopAppForm(this.$store, this.$store.state)
        PrePopApplicationService.getLoanOption(this.$store, this.$store.state)
      }

      if (this.$store.state.vehicleId && Number(this.$store.state.vehicleId) > 0 && (this.$store.state.affiliateConfig || this.$store.state.isDealer)) {
          VehicleService.loadVehicle(this.$store.state.affiliateConfig, this.$store.state, this.$store)
        }
      else if (!this.$store.state.form.vehicle.make && this.$store.state.vrm) {
          if (this.$store.state.isDealer) {
              VehicleService.loadDealerVehicleByVrm(this.$store.state, this.$store)
          }
          else {
              VehicleService.loadVehicleByVrm(this.$store.state, this.$store)
          }          
      }

      if (this.$store.state.form.utmParameters.agentPublicId) {
        AgentService.loadAgent(this.$store)
      }
    }).finally(() => {
      This.$store.dispatch('setUTMParams')
      This.$store.dispatch('setClickId')
      StateChangeEvent.subscribe(This, this.$store)
    })

    this.$appInsights.trackEvent({
      name: 'Application Form - Started',
      properties: {
        event_category: 'Application Form',
        event_action: 'Started',
        event_label: this.$store.state.GUID
      }
    });

    // SessionCam
    let scRec = document.createElement('script')
    scRec.type = 'text/javascript'
    scRec.src = "//d2oh4tlt9mrke9.cloudfront.net/Record/js/sessioncam.recorder.js"
    document.getElementsByTagName('head')[0].appendChild(scRec)

    SegmentService.checkSegmentLoaded().then( () => {
      //SegmentService.loadSegment(This.isProduction)

      let trackData = {
        guid: This.state.GUID
      };

      const vehicleType = RequestHelper.getQueryVariable('vehicle_type');

      if (vehicleType) {
        trackData.vehicleType = vehicleType;
      }
  
      SegmentService.trackApplicationFormStart(This.state, trackData);
      SegmentService.trackPage(This.state, This.state.GUID, "start" );
    })

    let cookieyesNecessary = CookieHelper.get('cookieyes-necessary');

    if (cookieyesNecessary === 'yes') {
      window.clarity('consent');

      if (typeof symplify !== 'undefined') {
        window.symplify.optIn(1);
      } else {
        document.addEventListener("symplify-loaded", function() {
          window.symplify.optIn(1);
        });
      }
    }
  },
  components: {
    ConsentManager,
    Tealium,
    AppForm,
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/theme/_partner.scss";

body {
  @include font-regular();
  background-color: #fff;
  font-size: 1.2rem;
  line-height: 1.625rem;
  color: $colour-type;
  margin: 0;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

a {
  color: $electric-purple;
  display: inline-block;

  img, svg {
    display: block
  }
}

p {
  &.shake {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &.small-print {
    font-size: 1rem;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  &.error {
    color: $colour-error;
    margin-top: 10px;
    line-height: 1.2;
  }

  &.progress-hint + section {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  a {
    display: inline;
  }

  + p {
    margin-top: 5px;
  }
}

b, strong {
  @include font-bold();
}

h1 {
  font-size: 2rem;
  margin: 20px 0 20px;

  @media (min-width: 1024px) {
    font-size: 2.25rem;
  }
}

h2 {
  font-size: 1.6rem;
}

h1, h2 {
  font-weight: 400;
}

h1, h2, label {
  line-height: 1
}

input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

::selection {
  background: $colour-selection;
}

header {
  width: 100%;
  padding: 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1024px) {
    padding: 1.5625rem 0;
  }

  .secure {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }
}

#app {
  width: 100%;
  max-width: none;
  margin: 0 auto;

  main {
    padding: 0 0px 0px;
  }

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;

    a {
      width: auto;
      flex-grow: 1;
      height: 3px;
      background: $electric-purple;
      margin-right: 2px;

      &:last-of-type {
        margin: 0
      }

      &.router-link-exact-active ~ a {
        background: $gray;
      }
    }
  }

  button {
    &::-moz-focus-inner {
      border: 0;
    }
  }

  .button {
    width: 100%;
    text-align: center;
    background: $electric-purple;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #fff;
    font-size: 1.4rem;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    text-decoration: none;

    @media (min-width: 1024px) {
      font-size: 1.25rem;
    }

    &:hover {
      cursor: pointer;
    }

    @supports (-webkit-overflow-scrolling: touch) and (display: -webkit-flex) {
      display: block;
    }

    svg {
      margin-left: .5rem;
      transform: translateY(3px);
      fill: #fff;
    }

    div {
      width: 100%;
      font-size: .8em;
      margin-top: .5em;
    }

    &.alpha {
      margin-top: 20px;
      border: 1px solid $electric-purple;
    }

    &.bravo {
      background: $label;
      color: $electric-purple;
      font-size: 1.2rem;
    }

    &.charlie {
      background: #fff;
      color: $electric-purple;
      font-size: 1.2rem;
    }

    &.alpha,
    &.bravo {
      @include font-bold();
    }

    &.bravo,
    &.charlie {
      svg {
        margin-left: 0;
        margin-right: .5rem;
        transform: rotate(180deg);
        fill: $electric-purple;
      }
    }
  }

  .disable-button {
    width: 100%;
    text-align: center;
    background: $dark-gray;
    padding: 20px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #fff;
    font-size: 1.4rem;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    text-decoration: none;

    @media (min-width: 1024px) {
      font-size: 1.25rem;
    }

    &:hover {
      cursor: pointer;
    }

    @supports (-webkit-overflow-scrolling: touch) and (display: -webkit-flex) {
      display: block;
    }

    svg {
      margin-left: .5rem;
      transform: translateY(3px);
      fill: #fff;
    }

    div {
      width: 100%;
      font-size: .8em;
      margin-top: .5em;
    }

    &.alpha {
      margin-top: 40px;
      border: 1px solid $dark-gray;
    }

    &.bravo {
      background: $label;
      color: $dark-gray;
      font-size: 1.2rem;
    }

    &.charlie {
      background: #fff;
      color: $dark-gray;
      font-size: 1.2rem;
    }

    &.alpha,
    &.bravo {
      @include font-bold();
    }

    &.bravo,
    &.charlie {
      svg {
        margin-left: 0;
        margin-right: .5rem;
        transform: rotate(180deg);
        fill: $electric-purple;
      }
    }
  }

  form {
    &.fade-enter-active,
    &.fade-leave-active {
      transition: all .15s ease;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }

    label {
      &.hidden {
        visibility: hidden;
        height: 0;
        display: none
      }

      &.big-label {
        font-size: 2.2rem;
        margin-bottom: 10px;
      }
    }

    [hidden] {
      display: none
    }
  }
}
.spacer {
  padding: 10px;
}
.hide-header-footer {
  header,
  footer,
  .progress {
    display: none;
  }
}

.duel-inputs.date-mod {
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  margin: 30px 0;
    justify-content: flex-start !important;
}
.formWrapper.date-month-mod, .formWrapper.date-day-mod {
  max-width: 70px !important; 
}
.formWrapper.date-year-mod {
  max-width: 85px !important; 
}
#app form h2 {
  color: #aaaaaa !important;
}
.duel-inputs.date-mod .formWrapper{
 padding: 0;
}
.formWrapper p.error{
  position: absolute;
}
.formWrapper{
  padding: 30px 0;
}
.input-container{
    position: relative;
    padding: 0 !important;
}
.input-container input{
    height: 4rem;
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    color: #4A4A4A;
}
.input-container .input-date input{
    height: 4rem;
    border: 0px solid #aaaaaa;
    border-radius: 5px;
    color: #4A4A4A;
}
.input-container + input:focus{
    outline: none;
    border-color: #5f4dd5;
    border: 2px solid #5f4dd5;
}

#date-mod:focus-within {
    outline: none;
    border-color: #5f4dd5;
    border: 2px solid #5f4dd5;
}

#date-mod > .labelDateWrapper .textlabel {
  display: none !important;
}
#date-mod > .labelDateWrapper .textlabelDate {   
  position: absolute;
  z-index: 999;
  left: 1rem;
  transform: translate(0, -285%);
  display: block !important;
  font-size: 0.8125rem;
  border: 1px solid white;
  background-color: white;
  color: #aaaaaa;
  @include font-bold();
}
#date-mod:focus-within > .labelDateWrapper .textlabel {
  position: absolute;
  z-index: 999;
  left: 1rem;
  transform: translate(0, -285%);
  display: block !important;
  font-size: 0.8125rem;
  border: 1px solid white;
  background-color: white;
  color: #5f4dd5;
  @include font-bold();
}
.input-container + .labelDateWrapper .textlabel {
  display: none;
}
 .input-container input:focus + .labelWrapper .textlabel, .input-container :not([value=""]) + .labelWrapper .textlabel{
    background-color: white;
    font-size: 0.8125rem;
    color: #aaaaaa;
    transform: translate(0, -240%);
    @include font-bold();
}
.input-container input:focus + .labelWrapper .textlabel, .input-container .container.focus .pound{
    border-color: #5f4dd5;
    color: #5f4dd5;
}
.input-container input + .labelWrapper .textlabel{
  transform: translate(0);
  font-size: 1.25rem;
  background-color: transparent;
}
.input-container .labelWrapper{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid transparent;
    background-color: transparent;
    pointer-events: none;
    display: flex;
    align-items: center;
}
.input-container .textlabel{
    font-size: 1.4rem;
    padding: 0 0.2rem;
    background-color: transparent;
    transform: translate(0);
    color: #aaaaaa;
    transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
}
.input-container input, .input-container .labelWrapper{
    font-size: 1.4rem;
    padding: 0 1rem;
}
.banner {
  background-color: #725bfc;
  padding: 1px 0;
}
.banner-text {
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: bold;
}

.banner-text a {
  color: #FFFFFF;
  text-decoration: underline;
  cursor: pointer;
}
</style>
